<template>
  <div class="agree">
        <h1>网站协议<i></i></h1>
        <p>为维护您自身权益，您在申请注册流程中确认同意本协议之前，应当认真阅读本协议各条款的具体表述（未成年人应在法定监护人陪同下审阅）。</p>
        <h4>1. 协议范围</h4>
        <p>1.1 本协议由您与享设计共同缔结，本协议具有合同效力。<br/>
        1.2 当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与享设计达成一致，成为享设计网站用户。<br/>
        1.3 阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。如果您以其他享设计允许的方式实际使用享设计服务时，即视为您表示同意接受本协议的全部内容。<br/>
        1.4 享设计若根据平台运营需要修改本协议，网站将会通过网站公告或电子邮件或站内信等方式提示您。如果您不同意对本协议内容所做的修改，则应立即停止使用本服务；如果您继续使用本服务的，则视为您同意对本协议内容所做的修改。</p>
        <h4>2. 用户注册与账号使用</h4>
        <p>2.1 用户注册是指用户登录享设计，并按要求填写相关信息并确认同意履行相关用户协议的过程。<br/>
        2.2 不当注册为享设计用户或超过其民事权利或行为能力范围从事交易的，其与享设计之间的服务协议自始无效，一经发现，享设计有权立即注销该用户，不当注册产生的风险自行承担，本网站不承担由此产生的一切责任。符合下列条件的可以注册并使用本网站的服务：（1）年满十八岁，并具有民事权利能力和民事行为能力的自然人；（2）无民事行为能力人或限制民事行为能力人应经过其监护人的同意；（3）用户提供明确的真实姓名、身份证和联系电话等认证信息。<br/>
        2.3 用户完成注册申请手续后，获得享设计账号的使用权。用户应提供及时、详尽及准确的个人资料。如果因注册信息不真实而引起的问题，并对问题发生所带来的后果，享设计不负任何责任。用户应当对以其用户账号进行的所有活动和事件负法律责任。<br/>
        2.4 用户需妥善保管自己注册的享设计账号与密码，禁止用户将其账号、密码透露、泄露、对外公布或以转让或出借等方式提供给他人使用，如用户发现其账号遭他人非法使用，应立即通知享设计。因黑客行为或用户的保管疏忽或不当导致账号、密码遭他人非法使用，由用户或非法使用人承担责任，享设计不承担任何责任。给享设计造成的所有损失由您赔偿，享设计网站有权冻结您的账户。<br/>
        2.5 用户在使用享设计网络服务过程中，必须遵循以下原则：<br/>
        2.5.1 遵守中国有关的法律和法规；<br/>
        2.5.2 遵守所有与享设计网络服务有关的网络协议、规定和程序；<br/>
        2.5.3 不得为任何非法目的而使用网络服务系统；<br/>
        2.5.4 不得利用享设计网络服务系统进行任何可能对互联网正常运转造成不利影响的行为；<br/>
        2.5.5 不得利用享设计提供的网络服务上传、展示或传播任何虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；<br/>
        2.5.6 不得侵犯其他任何第三方的专利权、著作权、商标权、名誉权、肖像权、隐私权、物权或其他任何合法权益；<br/>
        2.5.7 不得利用享设计网络服务系统进行任何不利于享设计的行为；<br/>
        2.5.8 用户充值VIP后若已使用下载服务，因提供虚拟产品服务的特殊性，一经下载作品源文件将会存储于本地电脑，代表已经使用该服务，一律不接受退款申请。若用户充值VIP后还未使用下载服务，未超过7日则可以申请退款。<br/>
        2.5.9 网络用户下载享设计平台的作品，应在作品的授权许可范围内使用。未经版权拥有者许可，仅限学习、交流、分享的范围内使用。同时，作品下载使用者遵守著作权法及其他相关法律的规定，不得侵害本网站及权利人的合法权利。<br/>
        2.6 为保护用户的账号安全，当账号在使用过程中出现安全风险（包括但不限于异常登录、异地下载、密码泄露、违规使用、恶意侵害等），网站将会提示需要手机验证。该机制是为保证账号本人在安全环境下合规使用，用户验证成功后可继续下载作品。请在手机验证时注意确认是用户本人手机号码，切勿将账号随意外借他人使用，避免造成损失。</p>
        <h4>3. 享设计的权利和义务</h4>
        <p>3.1 享设计应为交流、交易各方提供良好的网络服务支持，并努力让双方的交易更便捷、高效、安全。根据法律法规、规章制度、相关政策及享设计的规定，对用户上传至享设计的作品及其他信息进行初步审查，对涉嫌淫秽、暴力、反动等违法内容进行剔除。对严重违规或故意破坏平台规则的账户，享设计有权进行罚款或封号处理。对经初步审查合格的作品，予以审查通过，允许在享设计上展示。<br/>
        3.2 享设计有权在享设计网站的任意网页位置、任意时间投放各种广告，广告内容必须符合相关法律法规且不影响网站注册用户正常使用网站各项功能。<br/>
        3.3 享设计有权对用户上传至享设计的作品进行筛选，并根据相关政策、法律法规和享设计的规定来确定哪些内容属于可显示或被浏览的范围。享设计有权对用户上传的侵权作品做不予通过和下架删除，并对侵权用户进行警告、禁用、封号等处理。<br/>
        3.4 当第三方向享设计提出权利通知，向享设计出示经享设计审查认为充分的权属证明，主张享设计用户上传的作品侵犯其权益。享设计可以自行判断决定删除涉嫌侵权的作品，在必要的情况下，享设计可以将上传者的资料提供给第三方。<br/>
        3.5 享设计为您提供服务付出了大量的成本，为保证平台正常经营和维护，享设计有权根据网站相关规则向您收取一定的平台服务费。<br/>
        3.6 用户可以收藏不限量的优秀作品，但禁止用户多次高频地恶意下载大量作品，享设计有权利对违规用户禁用下载或封号等处理。<br/>
        3.7 享设计严格保护上传平台的设计师作品，未经作者和享设计的授权许可，禁止用户转载享设计的作品到第三方网站上进行二次售卖谋取利益，一经发现，立即封号，享设计将依法追究其相关法律责任。<br/>
        3.8 若用户侵犯第三方合法权益，或违反相关法律规定和享设计平台规定的，享设计有权对其进行禁用或封号处理，封号禁用后该账号不能注销。</p>
        <h4>4.免责声明</h4>
        <p>4.1 用户明确同意其使用享设计网络服务所存在的风险将完全由其自己承担；因其使用享设计网络服务而产生的一切后果也由其自己承担，享设计对用户不承担任何责任。<br/>
        4.2 享设计不担保网络服务一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。<br/>
        4.3 享设计不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由享设计实际控制的任何网页上的内容，享设计不承担任何责任。<br/>
        4.4 对于因不可抗力或享设计不能控制的原因造成的网络服务中断或其它缺陷，享设计不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。<br/>
        4.5 用户同意，对于享设计向用户提供的下列产品或者服务的质量缺陷本身及其引发的任何损失，享设计无需承担任何责任：<br/>
        4.5.1 享设计向用户免费提供的各项网络服务；<br/>
        4.5.2 享设计向用户赠送的任何产品或者服务；<br/>
        4.5.3 享设计向收费网络服务用户附赠的各种产品或者服务。</p>
        <h4>5. 违约赔偿</h4>
        <p>5.1 用户同意保障和维护享设计及其他用户的利益，如因以下情形给享设计及其关联方或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任（包括但不限于律师费用、诉讼费、仲裁费及其他合理费用）：<br/>
        5.1.1 用户或使用用户账号的其他人违反有关法律、法规或本协议项下的任何条款；<br/>
        5.1.2 任何第三人主张作品侵犯、盗用或违反其版权、商标权、专利权、商业秘密、隐私权、名誉权、公开权、肖像权、物权或其它知识产权或专有权利；<br/>
        5.1.3 违反本合同约定的其他行为。 </p>
      
  </div>
</template>

<script>
export default {

}
</script>

<style>
.agree{padding:47px 20px 20px; background: #fff;}
.agree h1{margin: 24px 0 32px; text-align: center; font-size:20px ;color: #2E2E2E; position: relative; font-weight: normal;}
.agree h1 i{display: block; width: 30px; height: 2px; background:#F85659 ; position: absolute; left: 0; right: 0; bottom: -10px; margin: auto; z-index: 1;}
.agree p{font-size: 14px; color: #B3B3B3; line-height: 24px; margin-bottom: 32px;}
.agree h4{font-size: 14px; color: #2E2E30; line-height: 24px; font-weight: normal;}
</style>